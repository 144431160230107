import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormDialogProps } from "shared/dialog/types";
import { ScreenSizeEnum, useScreenSize } from "shared/use-screen-size";
import { useValidateProjectUserInputs } from "./hooks/use-input-validation";
import { ProjectUserAdr } from "./inputs/project-user-adr";
import { ProjectUserEmail } from "./inputs/project-user-email";
import { CreateProjectUserFormData } from "./inputs/project-user-form-data";
import { ProjectUserName } from "./inputs/project-user-name";
import { ProjectUserPhoneNumber } from "./inputs/project-user-phone-number";
import { ProjectUserRoleField } from "./inputs/project-user-role";

interface Props extends FormDialogProps<CreateProjectUserFormData> {
  userEmails: (string | null | undefined)[];
}

export const CreateProjectUserDialog = (props: Props) => {
  const { userEmails } = props;
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();

  const {
    getValues,
    register,
    setError,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<CreateProjectUserFormData>({
    mode: "all",
    defaultValues: {
      role: "ProjectParticipant",
    },
  });

  const { validateCreateParameter, validateHasValidPhone, validateHasEmail, validateHasValidEmail, validateHasName } = useValidateProjectUserInputs(getValues);
  const { email, phone } = watch();

  const onAdd = () => {
    if (!validateHasName()) {
      setError("name", { type: "validate", message: t("dashboard.users.create.nameRequiredError") });
    }

    if (!validateHasEmail()) {
      setError("email", { type: "custom", message: t("dashboard.users.create.emailRequiredError") });
    } else if (!validateHasValidEmail()) {
      setError("email", { type: "custom", message: t("dashboard.users.create.emailInvalidError") });
    }

    if (!validateHasValidPhone(phone ?? "")) {
      setError("phone", { type: "validate", message: t("dashboard.users.create.phoneInvalidError") });
    }

    const isEmailInList = userEmails.some((item) => item?.toLowerCase() === email?.toLowerCase());
    if (isEmailInList) {
      setError("email", { type: "custom", message: t("dashboard.users.create.emailAlreadyExist") });
      return;
    }

    // this check ensures that we do not proceed if an input field is invalid
    if (!validateCreateParameter()) {
      return;
    }

    // Sets phone to empty if there are no spaces (only +XX) or no numbers after the first space
    if (phone && (phone.indexOf(" ") === -1 || phone.substring(phone.indexOf(" ") + 1) === "")) {
      setValue("phone", "");
    }

    props.onSubmit(getValues());
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          height: screenSize === ScreenSizeEnum.Mobile ? "100%" : "auto", // mobile is ignored
          margin: screenSize === ScreenSizeEnum.Mobile ? "32px 7px" : "32px",
          width: screenSize === ScreenSizeEnum.Mobile ? "100%" : "calc(100´%-64px)",
        },
      }}
      open={props.isOpen}
    >
      <DialogTitle component="div" textAlign={"center"} p={0}>
        <Typography variant="h5" color="primary.dark">
          {t("dashboard.users.create.title")}
        </Typography>
        <IconButton onClick={props.onClose} sx={{ position: "absolute", top: 0, right: 0 }}>
          <CloseIcon />
        </IconButton>
        <Divider sx={{ marginTop: 2 }} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <ProjectUserName register={register} fieldError={errors.name} />
          <ProjectUserEmail register={register} fieldError={errors.email} />
          <ProjectUserRoleField control={control} />
          <ProjectUserAdr register={register} />
          <ProjectUserPhoneNumber register={register} fieldError={errors.phone} value={getValues().phone} onChange={(value) => setValue("phone", value)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onAdd}>
          {t("common.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
