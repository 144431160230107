import { useState, type MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import { ScreenSizeEnum, useScreenSize } from "shared/use-screen-size";
import { useOnlineStatus } from "utils/connectivity/hook/use-online-status";
import { getPCA, logoutChannel } from "index";

export const ProfileSelectMenu = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { screenSize } = useScreenSize();
  const isOnline = useOnlineStatus();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const maxNameWidth = screenSize === ScreenSizeEnum.Mobile ? "100px" : "500px";

  const handleLogout = () => {
    logoutChannel.postMessage("logout");
    instance.logoutRedirect();
  };

  const msalInstance = getPCA();
  const account = msalInstance.getActiveAccount();

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button data-testid="project-selector-open-menu-btn" variant="text" disabled={!isOnline} onClick={openMenu} sx={{ color: "#FFFFFF", marginTop: "3px" }}>
        <Box display={"flex"}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PersonIcon sx={{ mr: 1 }} />
            <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: maxNameWidth, alignItems: "center" }} fontSize={"small"}>
              {account?.name ?? ""}
            </Box>
          </Box>
        </Box>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "primary.dark",
          },
          "& .MuiMenuItem-root:hover": {
            backgroundColor: "primary.light",
          },
        }}
      >
        <MenuItem disabled sx={{ color: "white" }}>
          <ListItemText>
            <Typography variant="subtitle1">{account?.username ?? ""}</Typography>
          </ListItemText>
        </MenuItem>

        <Divider color="white" sx={{ ml: 1, mr: 1 }} />

        <MenuItem data-testid="project-selector-menu-logout" onClick={handleLogout} sx={{ color: "white" }}>
          <ListItemText>
            <Typography variant="subtitle1">{t("common.logout")}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};
