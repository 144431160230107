import { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ExtraWorkAgreementResponse, GetProjectResponse } from "api/generatedApi";
import { sortCompareNumber, sortCompareString, sortCompareTime } from "utils/compares";
import { TextIcon } from "components/shared/text-icon/text-icon";
import { useDashboardRestrictions } from "shared/user-restrictions/use-dashboard-restrictions";
import { useOrder } from "shared/table/use-order";
import { DesktopExtraWorkAgreementRow } from "./desktop-row";
import { useExtraWorkAgreementFormatter } from "../hooks/format";
import { capitalizeString } from "utils/formats";
import { useDeleteExtraWorkAgreements } from "../hooks/use-delete-extra-work-agreements";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { TableHeader } from "shared/table/table-header";

interface Props {
  project: GetProjectResponse;
  agreements: ExtraWorkAgreementResponse[];
}

export const DesktopProjectExtraWorkAgreements = (props: Props) => {
  type AgreementSortableId = "Id" | "Name" | "Type" | "Hours" | "Payment";

  const { agreements, project } = props;
  const { t } = useTranslation();
  const { canSelectExtraWorkAgreements, canDeleteExtraWorkAgreements } = useDashboardRestrictions(project);
  const { getTypeByAgreementFormatted } = useExtraWorkAgreementFormatter();
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set<string>());
  const [checkedAll, setCheckedAll] = useState(false);
  const openDeleteExtraWorkAgreementsDialog = useDeleteExtraWorkAgreements({ project, selectedIds });
  const { direction, orderBy, getLabelProps } = useOrder<AgreementSortableId>("Name");

  const onCheck = useCallback(
    (id: string | undefined) => {
      if (id === undefined) return;
      let ids = new Set(selectedIds);
      if (ids.has(id)) {
        ids.delete(id);
      } else {
        ids.add(id);
      }
      setSelectedIds(ids);
    },
    [selectedIds]
  );

  const onCheckAll = (checked: boolean) => {
    let ids = new Set<string>();
    if (checked) {
      agreements?.forEach((item) => ids.add(item.extraWorkAgreementId ?? ""));
    }
    setSelectedIds(ids);
    setCheckedAll(!checkedAll);
  };

  const isFavoriteChecked = (id: string | undefined): boolean => {
    return selectedIds.has(id ?? "");
  };

  const sortedData = useMemo(() => {
    var sortedList = [...agreements];

    switch (orderBy) {
      case "Id":
        sortedList = [...sortedList].sort((a, b) => sortCompareString(direction, a?.extraWorkAgreementNumber, b?.extraWorkAgreementNumber));
        break;
      case "Name":
        sortedList = [...sortedList].sort((a, b) => sortCompareString(direction, a?.name, b?.name));
        break;
      case "Type":
        sortedList = [...sortedList].sort((a, b) => sortCompareString(direction, getTypeByAgreementFormatted(a), getTypeByAgreementFormatted(b)));
        break;
      case "Hours":
        sortedList = [...sortedList].sort((a, b) => sortCompareTime(direction, { hours: a.workTime?.hours, minutes: a.workTime?.minutes }, { hours: b.workTime?.hours, minutes: b.workTime?.minutes }));
        break;
      case "Payment":
        sortedList = [...sortedList].sort((a, b) => sortCompareNumber(direction, a.paymentDkr, b.paymentDkr));
        break;
    }

    return sortedList;
  }, [agreements, orderBy, direction, getTypeByAgreementFormatted]);

  const headerConfig: TableHeaderConfig<AgreementSortableId>[] = [
    {
      id: "Id",
      title: t("dashboard.extraWorkAgreements.table.id"),
      sortable: true,
      alignment: "left",
      testid: "dashboard-extra-work-agreements-header-id",
    },
    {
      id: "Name",
      title: t("common.name"),
      sortable: true,
      alignment: "left",
      testid: "dashboard-extra-work-agreements-header-name",
    },
    {
      id: "Type",
      title: t("dashboard.extraWorkAgreements.table.type"),
      sortable: true,
      alignment: "left",
      testid: "dashboard-extra-work-agreements-header-type",
    },
    {
      id: "Hours",
      title: t("common.time.hours"),
      sortable: true,
      alignment: "right",
      testid: "dashboard-extra-work-agreements-header-hours",
    },
    {
      id: "Payment",
      title: capitalizeString(t("common.currency")),
      sortable: true,
      alignment: "right",
      testid: "dashboard-extra-work-agreements-header-payment",
    },
  ];

  return (
    <Box sx={{ flex: 1, overflowY: "hidden", display: "flex", flexDirection: "column", width: "100%", height: "100%", pl: 4, pr: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Box sx={{ display: "flex", p: 2 }}>
          <TextIcon translateText="common.delete">
            <IconButton data-testid="dashboard-extra-work-agreements-delete-rows" disabled={!canDeleteExtraWorkAgreements(selectedIds)} onClick={openDeleteExtraWorkAgreementsDialog}>
              <DeleteOutlinedIcon />
            </IconButton>
          </TextIcon>
        </Box>
      </Box>
      <TableContainer sx={{ height: "calc(100vh - 240px)", overflowY: "auto", pb: "50px" }} component={Paper}>
        <Table stickyHeader>
          <TableHeader method="checkbox" headerConfig={headerConfig} getLabelProps={getLabelProps} checked={selectedIds.size === agreements.length} checkBoxDisabled={!canSelectExtraWorkAgreements()} onCheckAllProps={onCheckAll} />
          <TableBody>
            {sortedData.map((agreement) => {
              return <DesktopExtraWorkAgreementRow key={agreement.extraWorkAgreementId} project={project} row={agreement} onCheck={onCheck} checked={isFavoriteChecked(agreement.extraWorkAgreementId)} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
